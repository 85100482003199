import React, { useState, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { isMobile } from "react-device-detect";
import { AuthProvider, useAuth } from "./assets/Context/AuthContext";
import ProgressImage from "./assets/pages/ProgressImage";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";
import { CarritoProvider } from "./assets/Context/CarritoContext";

// Lazy loaded components
const LazyHome = React.lazy(() => import("./assets/pages/home"));
const LazyAboutUS = React.lazy(() => import("./assets/pages/US"));
const LazyAdmin = React.lazy(() => import("./assets/pages/Login"));
const LazyNOTFOUND = React.lazy(() => import("./assets/pages/404"));
const LazyAllProducts = React.lazy(() => import("./assets/pages/AllProducts"));
const LazySales = React.lazy(() => import("./assets/pages/Sales"));
const LazySalesMobile = React.lazy(() => import("./assets/pages/SalesMobile"));
const LazyRegister = React.lazy(() => import("./assets/pages/Register"));
const LazyCheckout = React.lazy(() => import("./assets/pages/Checkout"));
const LazyMission = React.lazy(() => import("./assets/pages/Mission"));
const LazyDashboard = React.lazy(() => import("./assets/pages/Dashboard"));

const App = () => {
  const auth = useAuth();
  const user = auth ? auth.user : null;
  const [isAdmin, setIsAdmin] = useState(false);

  const login = (email, password) => {
    if (
      email.trim().toLowerCase() === "gtaglea@hotmail.com" &&
      password.trim() === "Alonso33"
    ) {
      setIsAdmin(true);
      return true;
    } else {
      return false;
    }
  };

  return (
    <CarritoProvider>
      <Router>
        <Suspense fallback={<ProgressImage isLoading={true} />}>
          <Routes>
            {/* Critical routes */}
            <Route path="/" element={<LazyHome />} />
            <Route path="/Home" element={<LazyHome />} />
            <Route path="/AboutUS" element={<LazyAboutUS />} />
            <Route path="/OurMission" element={<LazyMission />} />
            <Route path="/AllProducts" element={<LazyAllProducts />} />

            {/* Authenticated and Admin routes */}
            <Route path="/login" element={<LazyAdmin login={login} />} />
            <Route path="/Register" element={<LazyRegister />} />
            <Route
              path="/Checkout"
              element={user ? <LazyCheckout /> : <Navigate to="/Register" />}
            />
            <Route
              path="/dashboard"
              element={isAdmin ? <LazyDashboard /> : <Navigate to="/login" />}
            />

            {/* Mobile vs Desktop detection */}
            <Route
              path="/Sales"
              element={isMobile ? <LazySalesMobile /> : <LazySales />}
            />

            {/* 404 Route */}
            <Route path="*" element={<LazyNOTFOUND />} />
          </Routes>
        </Suspense>
        {/* Analytics and SpeedInsights */}
        <SpeedInsights />
        <Analytics />
      </Router>
    </CarritoProvider>
  );
};

export default App;
